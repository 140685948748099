import React from "react"
import {Col, Row} from "antd";

const Matrix = (props) => {
    const {res} = props;
    return (
        <Row justify="center" className="mt-16">
            <Col className="matrix">
                <Row>
                    <Col span={12}
                         className="result_grid_birthday result_grid result-border-top result-border-left"
                    >
                        <Row>
                            <Col span={13}>Дата рождения:</Col>
                            <Col span={11}
                                 className="pl-16 numeral_result">{res[0][0]}</Col>
                        </Row>
                        <Row className="mt-16">
                            <Col span={13}>Доп. числа:</Col>
                            <Col span={11}
                                 className="pl-16 numeral_result">{res[0][1]}</Col>
                        </Row>
                    </Col>
                    <Col span={6}
                         className="result_grid result-border-top result-border-left"
                    >
                        Число судьбы
                        <Col className="numeral_result_dop">{res[0][2]}</Col>
                    </Col>
                    <Col span={6}
                         className="result_grid result-border-top result-border-left result-border-right result-grid-background">
                        Темперамент
                        <Col className="numeral_result_dop">{res[0][3]}</Col>
                    </Col>
                </Row>
                <Row>
                    <Col span={6}
                         className="result_grid result-border-top result-border-left">
                        Характер
                        <Col className="numeral_result_dop">{res[1][0]}</Col>
                    </Col>
                    <Col span={6}
                         className="result_grid result-border-top result-border-left">
                        Здоровье
                        <Col className="numeral_result_dop">{res[1][1]}</Col>
                    </Col>
                    <Col span={6}
                         className="result_grid result-border-top result-border-left">
                        Удача
                        <Col className="numeral_result_dop">{res[1][2]}</Col>
                    </Col>
                    <Col span={6}
                         className="result_grid result-border-top result-border-left result-border-right result-grid-background">
                        Цель
                        <Col className="numeral_result_dop">{res[1][3]}</Col>
                    </Col>
                </Row>
                <Row>
                    <Col span={6}
                         className="result_grid result-border-top result-border-left">
                        Энергия
                        <Col className="numeral_result_dop">{res[2][0]}</Col>
                    </Col>
                    <Col span={6}
                         className="result_grid result-border-top result-border-left">
                        Логика
                        <Col className="numeral_result_dop">{res[2][1]}</Col>
                    </Col>
                    <Col span={6}
                         className="result_grid result-border-top result-border-left">
                        Долг
                        <Col className="numeral_result_dop">{res[2][2]}</Col>
                    </Col>
                    <Col span={6}
                         className="result_grid result-border-top result-border-left result-border-right result-grid-background">
                        Семья
                        <Col className="numeral_result_dop">{res[2][3]}</Col>
                    </Col>
                </Row>
                <Row>
                    <Col span={6}
                         className="result_grid result-border-top result-border-left">
                        Интерес
                        <Col className="numeral_result_dop">{res[3][0]}</Col>
                    </Col>
                    <Col span={6}
                         className="result_grid result-border-top result-border-left">
                        Труд
                        <Col className="numeral_result_dop">{res[3][1]}</Col>
                    </Col>
                    <Col span={6}
                         className="result_grid result-border-top result-border-left">
                        Память
                        <Col className="numeral_result_dop">{res[3][2]}</Col>
                    </Col>
                    <Col span={6}
                         className="result_grid result-border-top result-border-left result-border-right result-grid-background">
                        Привычки
                        <Col className="numeral_result_dop">{res[3][3]}</Col>
                    </Col>
                </Row>
                <Row>
                    <Col span={6}
                         className="result_grid result-border-top result-border-left result-border-bottom result-grid-background">
                        Самооценка
                        <Col className="numeral_result_dop">{res[4][0]}</Col>
                    </Col>
                    <Col span={6}
                         className="result_grid result-border-top result-border-left result-border-bottom result-grid-background">
                        Быт
                        <Col className="numeral_result_dop">{res[4][1]}</Col>
                    </Col>
                    <Col span={6}
                         className="result_grid result-border-top result-border-left result-border-bottom result-grid-background">
                        Талант
                        <Col className="numeral_result_dop">{res[4][2]}</Col>
                    </Col>
                    <Col span={6}
                         className="result_grid result-border-top result-border-left result-border-right result-border-bottom result-grid-background">
                        Дух
                        <Col className="numeral_result_dop">{res[4][3]}</Col>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
};

export default Matrix;