import React from "react"
import {Col, Image} from "antd";
import Matrix from "./Matrix";
import HeaderToPrint from "./HeaderToPrint";

/**
 * Компонент для печати
 * @returns {*}
 * @constructor
 */
class ComponentToPrint extends React.Component {

    render() {
        return (<div style={{paddingBottom: "17px", textAlign: "center"}}>
                <Col span={24}>
                    <HeaderToPrint/>
                </Col>
                <Matrix res={this.props.res}/>
                <Image style={{marginTop: "56px"}} width={500} src="/contacts.svg"/>
            </div>
        )
    }
}

export default ComponentToPrint;
