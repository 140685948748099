import React from "react"

const HeaderToPrint = () => {
    return (
        <>
            <div className="header-print-div">
                <h1>Расчёт матрицы</h1>
                <h3>От ольги михайловой</h3>
            </div>

        </>
    )
};

export default HeaderToPrint;