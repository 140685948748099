import React, {useRef, useState} from "react"
import Header from "../components/Header";
import {ChevronLeft, Printer} from "react-feather";
import {NumerologyIcon} from "../assets/icons/NumerologyIcon";
import {Button, Col, DatePicker, Form, Modal} from "antd";
import ru from 'antd/es/date-picker/locale/ru_RU';
import moment from "moment"
import 'moment/locale/ru' // without this line it didn't work
import Matrix from "../components/Matrix";
import ReactToPrint from "react-to-print";
import ComponentToPrint from "../components/ComponentToPrint";
import "../assets/css/style.css"
import {MaskedInput} from "antd-mask-input";

moment.locale('ru');

const {confirm} = Modal;

/**
 * Домашняя страница системы
 * @returns {*}
 * @constructor
 */
const HomePage = () => {
    const componentToPrintRef = useRef();

    const [date, setDate] = useState(null);
    const [validate, setValidate] = useState(true);

    const [isResult, setIsResult] = useState(false);
    const [res, setRes] = useState([["", "", "", ""], ["", "", "", ""], ["", "", "", ""], ["", "", "", ""], ["", "", "", ""]]);

    const onFinish = (values) => {
        calculate();
    };

    const calculate = () => {
        if (!date || !validate) {
            showWarning();
            return;
        }
        let newRes = [["", "", "", ""], ["", "", "", ""], ["", "", "", ""], ["", "", "", ""], ["", "", "", ""]];
        // получение чисел из даты
        let d1 = Math.floor(date.date() / 10);
        let d2 = date.date() % 10;
        let m1 = Math.floor((date.month() + 1) / 10);
        let m2 = (date.month() + 1) % 10;
        let y1 = Math.floor(date.year() / 1000);
        let y2 = Math.floor(date.year() % 1000 / 100);
        let y3 = Math.floor(date.year() % 1000 % 100 / 10);
        let y4 = date.year() % 1000 % 100 % 10;

        // получние первого дополнительного числа
        let dop1_temp = d1 + d2 + m1 + m2 + y1 + y2 + y3 + y4;
        let dop1_1 = Math.floor(dop1_temp / 10);
        let dop1_2 = dop1_temp % 10;

        // получние второго дополнительного числа
        let dop2_temp = dop1_1 + dop1_2;
        while (dop2_temp > 12) {
            let dop2_temp_1 = Math.floor(dop2_temp / 10);
            let dop2_temp_2 = dop2_temp % 10;
            dop2_temp = dop2_temp_1 + dop2_temp_2;
        }
        let dop2_1 = Math.floor(dop2_temp / 10);
        let dop2_2 = dop2_temp % 10;

        // получение числа судьбы
        let fate_number = dop2_temp;
        while (fate_number > 11 || fate_number === 10) {
            let fate_number_1 = Math.floor(fate_number / 10);
            let fate_number_2 = fate_number % 10;
            fate_number = fate_number_1 + fate_number_2;
        }

        // получние третьего дополнительного числа
        let d1_temp = d1;
        if (d1_temp <= 0) {
            d1_temp = d2;
        }
        let dop3_temp = dop1_temp - d1_temp * 2;
        let dop3_1 = 0;
        if (dop3_temp > 0) {
            dop3_1 = Math.floor(dop3_temp / 10);
        }
        let dop3_2 = dop3_temp % 10;

        // получние четвертого дополнительного числа
        let dop4_temp = dop3_1 + dop3_2;
        while (dop4_temp > 12) {
            let dop4_temp_1 = Math.floor(dop4_temp / 10);
            let dop4_temp_2 = dop4_temp % 10;
            dop4_temp = dop4_temp_1 + dop4_temp_2;
        }
        let dop4_1 = Math.floor(dop4_temp / 10);
        let dop4_2 = dop4_temp % 10;

        let res_temp = [d1, d2, m1, m2, y1, y2, y3, y4, dop1_1, dop1_2, dop2_1, dop2_2, dop3_1, dop3_2, dop4_1, dop4_2];

        newRes[0][0] = moment(date).format('DD.MM.YYYY');
        newRes[0][1] = `${Math.abs(dop1_temp)}, ${Math.abs(dop2_temp)}, ${Math.abs(dop3_temp)}, ${Math.abs(dop4_temp)}`;
        newRes[0][2] = "" + Math.abs(fate_number);
        res_temp.forEach(num => {
            switch (num) {
                case 1:
                    newRes[1][0] = "" + newRes[1][0] + 1;
                    break;
                case 2:
                    newRes[2][0] = "" + newRes[2][0] + 2;
                    break;
                case 3:
                    newRes[3][0] = "" + newRes[3][0] + 3;
                    break;
                case 4:
                    newRes[1][1] = "" + newRes[1][1] + 4;
                    break;
                case 5:
                    newRes[2][1] = "" + newRes[2][1] + 5;
                    break;
                case 6:
                    newRes[3][1] = "" + newRes[3][1] + 6;
                    break;
                case 7:
                    newRes[1][2] = "" + newRes[1][2] + 7;
                    break;
                case 8:
                    newRes[2][2] = "" + newRes[2][2] + 8;
                    break;
                case 9:
                    newRes[3][2] = "" + newRes[3][2] + 9;
                    break;
                default:

            }
        });

        newRes[1][3] = Math.abs(newRes[1][0].length + newRes[1][1].length + newRes[1][2].length).toString();
        newRes[2][3] = Math.abs(newRes[2][0].length + newRes[2][1].length + newRes[2][2].length).toString();
        newRes[3][3] = Math.abs(newRes[3][0].length + newRes[3][1].length + newRes[3][2].length).toString();
        newRes[4][0] = Math.abs(newRes[1][0].length + newRes[2][0].length + newRes[3][0].length).toString();
        newRes[4][1] = Math.abs(newRes[1][1].length + newRes[2][1].length + newRes[3][1].length).toString();
        newRes[4][2] = Math.abs(newRes[1][2].length + newRes[2][2].length + newRes[3][2].length).toString();
        newRes[0][3] = Math.abs(newRes[3][0].length + newRes[2][1].length + newRes[1][2].length).toString();
        newRes[4][3] = Math.abs(newRes[1][0].length + newRes[2][1].length + newRes[3][2].length).toString();
        let tempRes = newRes.map(element => {
            return element.map(temp => temp.length && parseInt(temp) > 0 ? temp : "-");
        });
        setRes(tempRes);
        setIsResult(true);

    };

    function showWarning() {
        Modal.warning({
            title: 'Пожалуйста, укажите правильную дату рождения!'
        });
    }

    const onChangeDate = (e) => {
        let regexDate = '^(?:(?:31(\\/|-|\\.)(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec)))\\1|(?:(?:29|30)(\\/|-|\\.)(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))\\2))(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$|^(?:29(\\/|-|\\.)(?:0?2|(?:Feb))\\3(?:(?:(?:1[6-9]|[2-9]\\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\\d|2[0-8])(\\/|-|\\.)(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))\\4(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$';
        if (e.target.value.match(regexDate)) {
            setDate(moment(e.target.value, "DD.MM.YYYY"))
            setValidate(true)
        } else {
            setValidate(false)
        }

    };

    return (<div style={{paddingBottom: "17px"}}>
            <Col span={24}>
                <Header/>
            </Col>
            <Form
                name="basic"
                onFinish={onFinish}
            >
                <Col style={{marginTop: "33px"}} span={24} className="justify-content-center">
                    <Form.Item
                        style={{marginBottom: 0, minWidth: 240}}
                        name="birthday"
                    >
                        <DatePicker
                            require={true}
                            value={date}
                            className="date-picker"
                            onChange={(value) => {
                                setDate(value);
                                setValidate(true)
                            }}
                            format="DD.MM.YYYY"
                            locale={ru}
                            allowClear={false}
                            placeholder="Введите дату рождения"/>
                        <MaskedInput value={moment(date).format('DD.MM.YYYY')}
                                     className="date-picker-input"
                                     mask="11.11.1111"
                                     placeholder="Введите дату рождения"
                                     onChange={onChangeDate}
                                     allowClear/>
                    </Form.Item>
                </Col>
                <Col span={24} className="justify-content-center">
                    <Button htmlType="submit" type="primary" className="button justify-content-center">
                        <NumerologyIcon/>
                        Рассчитать матрицу
                    </Button>
                </Col>
                {isResult ?
                    <>
                        <Matrix res={res}/>
                        <Col span={24} className="justify-content-center text-uppercase">
                            <Button href="./Инструкция_к_матрице.pdf" target="_blank" type="primary"
                                    style={{width: "255px"}} className="button justify-content-center">
                                <NumerologyIcon/>
                                <span>СКАЧАТЬ ИНСТРУКЦИЮ</span>
                            </Button>
                        </Col>
                        <Col span={24} className="justify-content-center">
                            <Button href="https://taplink.cc/mihaylova_olya" type="primary" style={{width: "255px"}}
                                    className="button justify-content-center">
                                <ChevronLeft/> Вернуться назад
                            </Button>
                        </Col>
                        <Col span={24} className="justify-content-center">

                            <ReactToPrint
                                trigger={() => {
                                    return <Button type="primary" style={{width: "255px"}}
                                                   className="button justify-content-center">
                                        <Printer size={16} className="margin-icon-button"/>
                                        Распечатать матрицу
                                    </Button>;
                                }}
                                content={() => componentToPrintRef.current}
                            />
                            <div className="d-none"><ComponentToPrint ref={componentToPrintRef} res={res}/>
                            </div>

                        </Col>
                    </> :
                    <>
                        <Col span={24} className="justify-content-center">
                            <Button type="primary" href="https://taplink.cc/mihaylova_olya"
                                    className="button justify-content-center"><ChevronLeft/> Вернуться
                                назад</Button>
                        </Col>
                    </>
                }

            </Form>
        </div>
    )
};

export default HomePage;
